import { gql } from 'graphql-tag'
import { lowerFirst, upperFirst } from 'lodash-es'
import * as Modules from '../modules/fragments'
import * as Pages from '../pages/fragments'
import * as Blocks from '../blocks/fragments'
// import * as Events from '../events/fragments'
import * as GlobalData from '../global-data/fragments'
import * as Reports from '../reports/fragments'
import { toFragment } from '../utils/dynamic-utils'

const useEntryQueryBuilder = (preview: boolean) => {
  let entries: any[] = []
  let layerFragments: any = {}

  const setEntries = (_e: any[]) => {
    entries = _e
  }

  const setLayerFragments = (_layerFrags = {}) => {
    layerFragments = _layerFrags
  }

  const getFragments = () => {
    const contentTypesSet = new Set(entries.map((e) => e.contentType as string).filter(Boolean))
    const fragments = [...contentTypesSet]
      .map((type) => {
        const name = `fragment${upperFirst(type)}`
        const frag =
          Blocks[name] ||
          Modules[name] ||
          GlobalData[name] ||
          Pages[name] ||
          Reports[name] ||
          // OR the layer fragments
          layerFragments[name]
        return frag
        // || Events[name]
      })

      .filter(Boolean)

    return gql`
      ${fragments[0] || ''}
      ${fragments[1] || ''}
      ${fragments[2] || ''}
      ${fragments[3] || ''}
      ${fragments[4] || ''}
      ${fragments[5] || ''}
      ${fragments[6] || ''}
      ${fragments[7] || ''}
      ${fragments[8] || ''}
      ${fragments[9] || ''}
      ${fragments[10] || ''}
      ${fragments[11] || ''}
      ${fragments[12] || ''}
      ${fragments[13] || ''}
      ${fragments[14] || ''}
      ${fragments[15] || ''}
      ${fragments[16] || ''}
      ${fragments[17] || ''}
      ${fragments[18] || ''}
      ${fragments[19] || ''}
      ${fragments[20] || ''}
      ${fragments[21] || ''}
      ${fragments[22] || ''}
    `
  }

  const getSubquery = ({ id, sys, contentType }) => {
    const safeId = id || sys.id
    const name = lowerFirst(contentType)
    const fragment = toFragment(contentType)
    return `entry_${safeId}: ${name}(id: "${safeId}", locale: $locale, preview: ${preview}) {
      ...${fragment}
      contentType: __typename
    }`
  }

  const build = () => {
    const fragments = getFragments()
    // TODO: Check fragments
    const subqueries = entries.map((e) => getSubquery(e)).join('\r\n')
    const query = gql`
      ${fragments}
      query($locale: String!) {
        ${subqueries}
      }
    `
    return query
  }

  return {
    setEntries,
    setLayerFragments,
    build,
    entries
  }
}

export const getQueryForEntries = (entries: any[], { preview = false, layerFragments = {} }) => {
  const builder = useEntryQueryBuilder(preview)
  builder.setEntries(entries)
  builder.setLayerFragments(layerFragments)
  return builder.build()
}

export const getQueryForEntry = (entry: any, { preview = false, layerFragments = {} }) => {
  return getQueryForEntries([entry], { preview, layerFragments })
}
