import Definitions from '../definitions'
import { toCollection } from '../utils/dynamic-utils'
import type { DefinitionField, MapperImageOptions, MapperOptions } from '#layers/core/types'
import { BASE_TYPES, useTypeMappers } from './type-mappers'

const isCustomType = (type: string) => {
  return !BASE_TYPES.has(type)
}

// For fields with array type including multiple content types
// Return specific image options for a subType if defined in hook
// If not, use default imageOptions in field
// e.g. in page definition use different options only for certain subType
/* {
  id: 'genericSection',
  name: 'Generic Section',
  type: 'Array',
  subType: ['type1', 'type2', 'type3', 'type4'],
  validations: [{ size: { min: 0, max: 10 } }],
  hooks: {
    imageOptions: ({ contentType }) => {
      if (contentType === 'type1') {
        return {
          ...
          // custom image options only for type1
        }
      }
    }
  }
} */
const imageOptionsForField = (field: DefinitionField, args: any) => {
  let imageOptions: MapperImageOptions
  if (field.hooks?.imageOptions) {
    imageOptions = field.hooks.imageOptions(args)
    return imageOptions || field.imageOptions
  }

  return field.imageOptions
}

const dynamicMapField = (field: DefinitionField, data: any, options: MapperOptions, mapperForType: Function) => {
  const id = field.id
  const type = field.type
  const itemData = data[id]

  function addOptionalContentType(entry: any, type: string) {
    if (entry && options.withContentTypes && isCustomType(type)) {
      entry.contentType = type
    }
    return entry
  }

  if (type === 'Symbol' || type === 'Text' || type === 'RichText' || type === 'Integer') {
    return itemData || ''
  }

  // Map array of specific subType
  // Cover the cases where subType can be:
  // String: subType: 'ModuleNavigationLink'
  // Array: `subType: ['ModuleNavigationLink', 'ModuleMedia']`
  try {
    if (type === 'Array') {
      // We can have an Array of items with type 'Symbol' in the items that represents a "list of strings" in the CMS
      // e.g. { type: Array, items: 'Symbol' }
      if (field.subType === 'Symbol') {
        return itemData || []
      }

      const items = data[toCollection(id)]?.items
      if (!items) {
        return null
      }

      // We have an Array of items of multiple subTypes ex
      // e.g. { type: Array, subType: ['BlockContent', 'BlockGroup'] }
      if (Array.isArray(field.subType)) {
        return items
          .map((i) => {
            const fieldMapper = mapperForType(i?.contentType)
            const imageOptions = imageOptionsForField(field, { contentType: i?.contentType })
            const mappedField = fieldMapper(i, { ...options, imageOptions })
            addOptionalContentType(mappedField, i?.contentType)
            return mappedField
          })
          .filter(Boolean)
      }

      // We have an Array of items of the same subType
      // e.g. { type: Array, subType: 'BlockContent' }
      const imageOptions = imageOptionsForField(field, { contentType: field.subType })
      const additionalOptions = field.options || {}
      const subTypeMapper = mapperForType(field.subType)

      return items.map((i) => {
        const mappedItem = subTypeMapper(i, { ...options, ...additionalOptions, imageOptions })
        addOptionalContentType(mappedItem, field.subType)
        return mappedItem
      })
    }
  } catch (e) {
    console.error(e)
  }

  // Else, specific content types
  // Cover the cases where type can be:
  // String: type: 'ModuleNavigationLink'
  // Array: `type: ['ModuleNavigationLink', 'ModuleMedia']`
  try {
    const typeToMap = Array.isArray(type) ? itemData?.contentType : type
    const imageOptions = imageOptionsForField(field, { contentType: typeToMap })
    const additionalOptions = field.options || {}
    const typeMapper = mapperForType(typeToMap)
    const mappedItem = typeMapper(itemData, { ...options, ...additionalOptions, imageOptions })
    addOptionalContentType(mappedItem, typeToMap)
    return mappedItem
  } catch (e) {
    console.error(e)
  }
}

const mapName = (field: DefinitionField): string => {
  const id = field.id
  return field.type === 'Array' ? toCollection(id) : id
}

/**
 * Page dynamic mapper
 * normalizes data to be used in pages
 */
export default (data: any, options: MapperOptions, layerMappers: any) => {
  if (!data) {
    return {}
  }

  const { mapperForType } = useTypeMappers(layerMappers)

  // Extract contentType
  const contentType = options.contentType || data.contentType
  const fields: DefinitionField[] = Definitions[contentType] ? Definitions[contentType].fields : null

  if (!fields) {
    throw new Error(`Content type ${contentType} not supported in the definitions`)
  }

  const mappedItems = fields.map((field: DefinitionField) => [
    mapName(field),
    dynamicMapField(field, data, options, mapperForType)
  ])
  return Object.fromEntries(mappedItems)
}
