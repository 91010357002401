import { mapperBlockLogoBar } from '../../blocks/block-logo-bar/mapper-block-logo-bar'
import { mapperModuleMedia } from '../../modules/module-media/mapper-module-media'
import { mapperModuleMediaCard } from '../../modules/module-media-card/mapper-module-media-card'
import type { MapperOptions, ModuleMedia } from '#layers/core/types'

/**
 * MediaBlocks mapper

 */
export default (data: any, options: MapperOptions) => {
  if (!data || !Object.keys(data).length) {
    return null
  }
  if (data.contentType === 'BlockLogoBar') {
    return {
      content: mapperBlockLogoBar(data, options),
      componentName: 'DsLogoBar'
    }
  }
  if (data.contentType === 'ModuleMediaCard') {
    const moduleMediaCardData = mapperModuleMediaCard(data, options)
    return {
      content: {
        captionTitle: moduleMediaCardData.title,
        captionText: moduleMediaCardData.description,
        image: {
          src: (moduleMediaCardData.image as ModuleMedia)?.original?.transform,
          name: moduleMediaCardData.title
        }
      },
      componentName: 'DsFigureElement'
    }
  }
  if (data.contentType === 'ModuleMedia') {
    return {
      content: {
        image: data ? mapperModuleMedia(data, options.imageOptions) : null
      },
      componentName: 'DsImageElement'
    }
  }
  return null
}
