import {
  mapperModuleCompanyFormulaItem,
  mapperModuleMedia,
  mapperModuleNavigationLink,
  mapperModuleUsp,
  mapperModuleVideo
} from '#layers/core/data-layer/modules/mappers'
import { mapperBlockMediaCardGroup, mapperBlockQuote } from '#layers/core/data-layer/blocks/mappers'

import imageSizes from '#layers/core/constants/imageSizes'
import type { MapperOptions } from '#layers/core/types'

/**
 * Page careers mapper
 * normalizes data to be used in pages
 */
export default (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }
  const cardImageOptions = {
    resize: true,
    lazy: true,
    sizes: {
      small: { width: 272, height: 153, aspectRatio: '16/9' },
      medium: { width: 576, height: 324, aspectRatio: '16/9' },
      large: { width: 576, height: 324, aspectRatio: '16/9' }
    }
  }
  const quoteImageOptions = {
    resize: true,
    lazy: true,
    sizes: {
      small: { width: 375, height: 211, aspectRatio: '16/9' },
      medium: { width: 421, height: 562, aspectRatio: '3/4' },
      large: { width: 776, height: 436, aspectRatio: '16/9' }
    }
  }

  return {
    headingTitle: data.headingTitle || '',
    headingMedia: mapperModuleVideo(data.headingMedia, { resize: true, sizes: imageSizes.columns12_5269 }),
    introTitle: data.introTitle || '',
    introDescription: data.introDescription || '',
    introLink: mapperModuleNavigationLink(data.introLink, options.getUrl),
    companyFormulaTitle: data.companyFormula?.title || '',
    companyFormulaDescription: data.companyFormula?.description || '',
    companyFormulaItems: data.companyFormula?.itemsCollection?.items.map((i) =>
      mapperModuleCompanyFormulaItem(i, false)
    ),
    quote: mapperBlockQuote(data.quote, { ...options, imageOptions: quoteImageOptions }),
    careerTypes: mapperBlockMediaCardGroup(data.careerTypes, { ...options, imageOptions: cardImageOptions }),
    locationsMedia: mapperModuleMedia(data.locationsMedia, {
      resize: true,
      lazy: true,
      sizes: imageSizes.columns12_5269
    }),
    locationsTitle: data.locationsTitle || '',
    benefitsTitle: data.benefitsTitle || '',
    benefitsItems: data.benefitsCollection?.items.map(mapperModuleUsp)
  }
}
