import mapperImage from '../../utils/mappers/mapper-image'
import type { MapperImageOptions, ModuleMedia } from '#layers/core/types'

export const mapperModuleMedia = (
  data: any,
  imageOptions?: MapperImageOptions | MapperImageOptions[]
): ModuleMedia | ModuleMedia[] | null => {
  if (!data) {
    return null
  }

  if (data.frontifyAsset) {
    return mapWithFrontify(data, imageOptions)
  } else {
    return mapWithBynder(data, imageOptions)
  }
}

const mapWithFrontify = (
  data: any,
  imageOptions?: MapperImageOptions | MapperImageOptions[]
): ModuleMedia | ModuleMedia[] => {
  const frontifyAsset = data.frontifyAsset?.[0]
  const original = {
    src: frontifyAsset?.src || '',
    alt: data.alt || data.name,
    transform: frontifyAsset?.preview_url || '',
    extension: frontifyAsset?.ext || '',
    name: data.name || '',
    color: data.color || '',
    isPublic: true,
    size: {
      width: frontifyAsset?.width,
      height: frontifyAsset?.height
    },
    focalPoint: frontifyAsset?.focalPoint || null,
    frontifyType: frontifyAsset?.type || '',
    isFrontifyAsset: true
  }
  // when the asset is marked as public we can return the original here (for pdf assets)
  const link = {
    url: frontifyAsset?.generic_url || frontifyAsset?.src || '',
    title: data.name || '',
    download: true,
    nuxt: false,
    openInNewWindow: true
  }

  // No image options provided
  if (!imageOptions) {
    return {
      original,
      link,
      lazy: false,
      source: (data.source as string) || '',
      small: original,
      medium: original,
      large: original,
      contentType: data.contentType
    }
  }

  const resizeImg = (data: any, imageOptions: MapperImageOptions) => {
    const resized = mapperImage(original, imageOptions.sizes, imageOptions.recolor, imageOptions.zoom)
    return {
      original,
      link,
      lazy: !!imageOptions?.lazy,
      caption: data.caption || '',
      source: data.source || '',
      ...resized,
      contentType: data.contentType
    }
  }

  // If an array of options is passed, we return an array of resized images
  if (Array.isArray(imageOptions)) {
    return imageOptions.map((options) => resizeImg(data, options))
  }
  // Most common scenario
  return resizeImg(data, imageOptions)
}

const mapWithBynder = (
  data: any,
  imageOptions?: MapperImageOptions | MapperImageOptions[]
): ModuleMedia | ModuleMedia[] => {
  const original = {
    src: data.src?.[0]?.original || data.src?.[0]?.src || '',
    alt: data.alt || data.name,
    transform: data.src?.[0]?.thumbnails?.transformBaseUrl || '',
    extension: data.src?.[0]?.extension?.[0] || '',
    name: data.name || '',
    color: data.color || '',
    isPublic: data.src?.[0].isPublic || false,
    size: {
      width: data.src?.[0]?.width,
      height: data.src?.[0]?.height
    },
    isFrontifyAsset: false
  }
  // when the asset is marked as public we can return the original here (for pdf assets)
  const link = {
    url: data.src?.[0]?.original || data.src?.[0]?.src || '',
    title: data.name || '',
    download: true,
    nuxt: false,
    openInNewWindow: true
  }

  // No image options provided
  if (!imageOptions) {
    return {
      original,
      link,
      lazy: false,
      source: (data.source as string) || '',
      small: original,
      medium: original,
      large: original
    }
  }

  const resizeImg = (data: any, imageOptions: MapperImageOptions) => {
    const resized = mapperImage(original, imageOptions?.sizes)
    return {
      original,
      link,
      lazy: !!imageOptions?.lazy,
      caption: data.caption || '',
      source: data.source || '',
      ...resized
    }
  }

  // If an array of options is passed, we return an array of resized images
  if (Array.isArray(imageOptions)) {
    return imageOptions.map((options) => resizeImg(data, options))
  }
  // Most common scenario
  return resizeImg(data, imageOptions)
}
