import type { MapperOptions } from '#layers/core/types'
import { mapperKnowledgeHubArticleSummary } from './mapper-knowledge-hub-article-summary'

export const mapperKnowledgeHubArticlesSummaries = (items: any[], options: MapperOptions) => {
  // filter items that has no valid link url to prevent rendering articles (in client side) that are published but not yet built statically
  return items
    ?.filter(Boolean)
    .map((item) => mapperKnowledgeHubArticleSummary(item, options))
    .filter((item) => !!item?.link?.url || !!item?.link?.lazyUrl)
}
