import { mapperModuleMedia } from '../../modules/mappers'
import mapperGlobalDataCity from '../global-data-city/mapper-global-data-city'
import type { MapperImageOptions } from '#layers/core/types'

/**
 * globalDataOffice, normalize Global Data - Office data
 * @param {Object} data - cms response data
 * @param {Object} options - { resize: Boolean, sizes: Object }
 * @return {Object} the normalized data
 */

export default (data: any, imageOptions?: MapperImageOptions) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    name: data.name || '',
    image: mapperModuleMedia(data.image, imageOptions) || null,
    city: mapperGlobalDataCity(data.city) || null,
    country: data.city?.country ? { id: data.city.country.sys.id, name: data.city.country.name } : null,
    region: data.city?.country?.region
      ? {
          id: data.city.country.region.sys.id,
          _name: data.city.country.region.internalName,
          name: data.city.country.region.name,
          uniqueId: data.city.country.region.uniqueId
        }
      : null,
    addressLine1: data.addressLine1 || '', // TODO: Format address
    addressLine2: data.addressLine2 || '', // TODO: Format address
    phoneNumber: data.phoneNumber || '',
    addressLink: data.addressLink || ''
  }
}
