import { gql } from 'graphql-tag'
import { fragmentGlobalDataArticleType, fragmentGlobalDataTopic } from '../../global-data/fragments'
import { fragmentModuleMedia } from '../../modules/fragments'

export default gql`
  ${fragmentGlobalDataTopic}
  ${fragmentGlobalDataArticleType}
  ${fragmentModuleMedia}
  fragment PageKnowledgeHubDetailFragment on PageKnowledgeHubDetail {
    sys {
      id
    }
    title
    slug
    topicsCollection {
      items {
        ...GlobalDataTopicFragment
      }
    }
    articleType {
      ...GlobalDataArticleTypeFragment
    }
    image {
      ...ModuleMediaFragment
    }
    fpZoom
    shortDescription
    date
    minutesToRead
  }
`
