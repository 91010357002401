import { gql } from 'graphql-tag'
import { lowerFirst, upperFirst } from 'lodash-es'
import type { FieldItem } from 'contentful'
import * as Modules from '../modules/fragments'
import * as Blocks from '../blocks/fragments'
import * as GlobalData from '../global-data/fragments'
import * as Pages from '../pages/fragments'
import Definitions from '../definitions/index'
import queryPageFields from '../pages/page/query-page-fields'
import { toCollection, toFragment, toQueryBrackets, toSpread } from '../utils/dynamic-utils'

// A filed is considered `lazy` when the lazy prop is true and it does not have a specific Lazy contentType
// @TODO Nico and Adrian to review `lazy` fields approach
function isFieldLazy(field: any) {
  const fieldType = Array.isArray(field.type) ? field.type.join(' ') : field.type
  const subType = Array.isArray(field.subType) ? field.subType.join(' ') : field.subType
  return field.lazy && !fieldType?.includes('Lazy') && !subType?.includes('Lazy')
}

function getReferenceFieldQuery(field, fieldType) {
  if (isFieldLazy(field)) {
    return toQueryBrackets(['contentType: __typename', `sys ${toQueryBrackets('id')}`].join(' '))
  }

  return Array.isArray(fieldType)
    ? toQueryBrackets(
        [
          'contentType: __typename',
          ...fieldType.map((t) => `... on ${t} ${toQueryBrackets(toSpread(toFragment(t)))}`)
        ].join(' ')
      )
    : toQueryBrackets(toSpread(toFragment(fieldType)))
}

const usePageQueryBuilder = (preview: boolean) => {
  let contentType: string | null = null
  let fields: any[] = []
  let limit: number = 1
  let total: boolean = false
  let skip = 0
  let parent = null
  let where = null
  let order = null
  let layerFragments = {}

  const batchSize = 100

  const setContentType = (_contentType: string) => {
    contentType = lowerFirst(_contentType)
  }

  const setLimit = (_limit: number) => {
    limit = _limit
  }

  const setLayerFragments = (_layerFragments: any) => {
    layerFragments = _layerFragments
  }

  const setTotal = (_total: boolean) => {
    total = _total
  }

  const setSkip = (_skip) => {
    skip = _skip
  }

  const setFields = (_fields) => {
    fields = _fields
  }

  const setOrder = (_order) => {
    order = _order
  }

  const setWhereClause = (_where) => {
    where = _where
  }

  const setParent = (_parent) => {
    parent = _parent
  }

  // A field is considered `lazy` when the lazy prop is true and it does not have a specific Lazy contentType
  // @TODO Nico and Adrian to review `lazy` fields approach
  const isFieldLazy = (field: FieldItem) => {
    const fieldType = Array.isArray(field.type) ? field.type.join(' ') : field.type
    const subType = Array.isArray(field.subType) ? field.subType.join(' ') : field.subType
    return field.lazy && !fieldType?.includes('Lazy') && !subType?.includes('Lazy')
  }

  const getFragments = () => {
    // Get Fragments only for fields that are not `lazy` loaded
    const notLazyFields = fields.filter((f) => !isFieldLazy(f))
    // Apply 2 times `flat` because type and subType can be either string or array
    const typesAndSubTypesSet = new Set(
      notLazyFields
        .flatMap((f) => [f.type, f.subType])
        .flat()
        .filter(Boolean)
    )
    const fragments = [...typesAndSubTypesSet]
      .map((type) => {
        const name = `fragment${upperFirst(type)}`
        return Blocks[name] || Modules[name] || GlobalData[name] || Pages[name] || layerFragments[name]
        // TODO add as needed
        // || Events[name] || Campaigns[name] || Reports[name]  || Terminals[name]
      })
      .filter(Boolean)

    if (!fragments.length) {
      return ''
    }

    return gql`
      ${fragments[0] || ''}
      ${fragments[1] || ''}
      ${fragments[2] || ''}
      ${fragments[3] || ''}
      ${fragments[4] || ''}
      ${fragments[5] || ''}
      ${fragments[6] || ''}
      ${fragments[7] || ''}
      ${fragments[8] || ''}
      ${fragments[9] || ''}
      ${fragments[10] || ''}
      ${fragments[11] || ''}
      ${fragments[12] || ''}
      ${fragments[13] || ''}
      ${fragments[14] || ''}
      ${fragments[15] || ''}
      ${fragments[16] || ''}
      ${fragments[17] || ''}
      ${fragments[18] || ''}
    `
  }

  const getReferenceFieldQuery = (field, fieldType) => {
    /**
     * special case for 3rd level pages that have a field called `parent` that is a reference to the parent page. This is needed when the 2nd level page is dynamic.
     * examples:
     *     - Country pages: `./pages/payment-methods-guides/_region/[country].vue`
     *     - Podcast episodes: `./pages/podcast/behind-the-figures-2022/[country].vue`
     */

    if (field.id === 'parent') {
      return toQueryBrackets(['contentType: __typename', `sys ${toQueryBrackets('id')}`].join(' '))
    }

    if (isFieldLazy(field)) {
      let query = toQueryBrackets(
        ['contentType: __typename', 'lazy: __typename', `sys ${toQueryBrackets('id')}`].join(' ')
      )
      if (Array.isArray(fieldType)) {
        query = toQueryBrackets(toSpread(`on Entry ${query}`))
      }
      return query
    }

    return Array.isArray(fieldType)
      ? toQueryBrackets(
          [
            'contentType: __typename',
            ...fieldType.map((t) => `... on ${t} ${toQueryBrackets(toSpread(toFragment(t)))}`)
          ].join(' ')
        )
      : toQueryBrackets(toSpread(toFragment(fieldType)))
  }

  const getItems = () => {
    return fields.map((field) => {
      let item = `${field.id}`
      if (['Text', 'Symbol', 'Date', 'Integer', 'Boolean'].includes(field.type)) {
        // Don't add anything
      } else if (field.type === 'RichText') {
        item += toQueryBrackets('json')
      } else if (field.type === 'Array') {
        if (field.subType === 'Symbol') {
          // Don't add anything
        } else {
          const collectionName = toCollection(field.id)
          const subType = getReferenceFieldQuery(field, field.subType)
          item = `${collectionName} {
            items ${subType}
          }`
        }
      } else {
        item += getReferenceFieldQuery(field, field.type)
      }
      return item
    }).join(`
    `)
  }

  const getWhereClause = () => {
    // if there is a specific where cluase, it overrides all the conditions
    if (where) {
      return `where: { ${where} },`
    }
    // if there is a slug and parent page, use both in the where condition
    const withSlug = limit === 1
    if (withSlug && parent) {
      return 'where: { slug: $slug, parent: { sys: { id: $parentId } } },'
    }
    // if there is only slug use it in the where condition
    if (withSlug) {
      return 'where: { slug: $slug },'
    }

    // otherwise no where clause
    return ''
  }

  const build = () => {
    const fragments = getFragments()
    const items = getItems()
    const name = toCollection(contentType)
    const withSlug = limit === 1
    const whereClause = getWhereClause()

    return gql`
    ${fragments}
  query(${withSlug ? '$slug: String!,' : ''} ${parent ? '$parentId: String!,' : ''} $locale: String!) {
    page: ${name}(limit: ${limit}, ${order ? `order: ${order},` : ''} ${whereClause} locale: $locale, preview: ${preview}, skip: ${skip}) {
      ${total ? 'total' : ''}
      items {
        ${queryPageFields}
        ${items}
      }
    }
  }
`
  }

  return {
    setContentType,
    setFields,
    setLimit,
    setTotal,
    setSkip,
    setWhereClause,
    setParent,
    setOrder,
    setLayerFragments,
    build
  }
}

export const getQueryForContentType = (
  contentType: any,
  {
    limit = 1,
    fields = [],
    skip = 0,
    order = null,
    whereClause = null,
    preview = false,
    withParent = false,
    withTotal = false,
    layerFragments = {}
  }: {
    limit?: number
    fields?: string[]
    skip?: number
    withTotal?: boolean
    whereClause?: boolean
    withParent?: boolean
    order?: any
    preview?: boolean
    layerFragments: any
  }
) => {
  const contentTypeDefinition = Definitions[contentType]

  if (!contentTypeDefinition) {
    throw new Error(`Content type ${contentType} not supported in the definitions`)
  }

  const pageBuilder = usePageQueryBuilder(preview)

  pageBuilder.setContentType(contentTypeDefinition.contentType.replace('Content', ''))
  pageBuilder.setFields(fields?.length ? fields : contentTypeDefinition?.fields)
  pageBuilder.setLimit(limit)
  pageBuilder.setTotal(withTotal)
  pageBuilder.setOrder(order)
  pageBuilder.setSkip(skip)
  pageBuilder.setParent(withParent)
  pageBuilder.setWhereClause(whereClause)
  pageBuilder.setLayerFragments(layerFragments)

  return pageBuilder.build()
}
