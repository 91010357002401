import { mapperBlockContent } from '../block-content/mapper-block-content'
import { mapperBlockLogoBar } from '../block-logo-bar/mapper-block-logo-bar'
import { mapperModuleMediaCard } from '../../modules/mappers'
import type { ContentTypeName, MapperOptions } from '#layers/core/types'

/**
 * BlockTabContent
 */
export const mapperBlockTabContent = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  const getComponentName = (contentType: ContentTypeName) => {
    if (contentType === 'GlobalDataPersona') {
      return 'InvestorRelationsGovernanceBoards'
    }
    if (contentType === 'ModuleTable') {
      return 'InvestorRelationsGovernanceCommitteeComposition'
    }
    if (contentType === 'ModuleMedia') {
      return 'InvestorRelationsGovernanceDocuments'
    }
    return null
  }

  const mapItem = (item: any, options: MapperOptions) => {
    const contentType: ContentTypeName = item.contentType
    if (contentType === 'BlockContent') {
      return {
        ...mapperBlockContent(item, options),
        type: contentType
      }
    }

    if (contentType === 'BlockLogoBar') {
      return {
        ...mapperBlockLogoBar(item, options),
        type: contentType
      }
    }

    if (contentType === 'ModuleMediaCard') {
      return {
        ...mapperModuleMediaCard(item, options),
        type: contentType
      }
    }

    return { ...item, type: contentType }
  }

  return {
    title: data?.title || '',
    description: data?.description || null,
    items: data?.tabContentCollection?.items.map((i: any) => mapItem(i, options)) || [],
    componentName: getComponentName(data?.tabContentCollection?.items?.[0]?.contentType)
  }
}
