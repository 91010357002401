import type { PageMeta } from '#layers/core/types'
import copy from 'fast-copy'
export const usePageSEO = (context: {
  targetSite: string
  routeParams: LocalizedRouteParams
  pageMeta: PageMeta | null
  structuredData: any | null
}) => {
  // TODO: Move somewhere else and import it
  const BRAND_LABEL = 'Adyen'

  // Set title, descriptions and Open Graph meta tags
  useSeoMeta({
    title: () => context.pageMeta?.title || '',
    ogTitle: () => context.pageMeta?.title,
    description: () => context.pageMeta?.description,
    ogDescription: () => context.pageMeta?.description,
    ogImage: () => context.pageMeta?.image,
    // keywords: () => context.pageMeta?.keywords, // Deprecated  https://web.dev/learn/html/metadata/#keywords
    robots: () => context.pageMeta?.robots,
    ogUrl: () => context.pageMeta?.url,
    twitterCard: () => 'summary',
    twitterSite: () => `@${BRAND_LABEL}`,
    twitterTitle: () => context.pageMeta?.title,
    twitterDescription: () => context.pageMeta?.description,
    twitterCreator: () => `@${BRAND_LABEL}`
  })

  // Generate translations alternate links and Open Graph alternate locales
  const setI18nParams = useSetI18nParams()
  if (Object.keys(context.routeParams || {}).length > 0) {
    // console.log('hey', context.routeParams)
    // Only set param is it is really a param
    if (typeof context.routeParams.en === 'object') {
      // console.log('setting i18n params')
      setI18nParams(context.routeParams)
    }
  } else {
    console.warn('/// NO route params were retrieved')
  }

  const i18nHead = useLocaleHead({
    seo: true
  })

  const headLinks: any[] = copy(i18nHead.value?.link || [])

  const defaultLink = headLinks.find((el) => el.hreflang === 'x-default')

  // Let's remove this for now to see if it is creating duplicated tags
  // const slugParts: string[] = defaultLink?.href.split('/') || []
  // const siteSlug = slugParts.length > 0 ? slugParts[slugParts.length - 1] : 'main'

  // // Because of concurrency, we want really unique HIDs
  // const concurrentHeadLinks = headLinks.map((l) => ({
  //   ...l,
  //   hid: `${l.hid}-${siteSlug}`
  // }))
  // console.error('xd', JSON.stringify(concurrentHeadLinks))
  // test trigger release

  // Main site scenario
  if (!defaultLink) {
    headLinks.push({
      hid: `i18n-xd`,
      href: useRuntimeConfig().public.mainSiteUrl,
      hreflang: 'x-default',
      rel: 'alternate'
    })
  }

  // @TODO: [Nico] Keep this commented code block until we validate the final alternate links approach

  // - Generate alternate link tags only for existing route locales
  /**
   * routeLocales - List of locales codes available for the current page
   * @type {String[]} - Locales ISO codes array e.g ['en', 'nl-NL', ...]
   */
  // const routeLocales = Object.keys(i18nParams.value || {}).map(getLocaleIso)

  // Not needed after the i18n lib update
  // const routeLocalesIso = routeLocales.length ? routeLocales.map(locale => getLocaleIso(locale)) : locales.map(item => item.iso)

  // TODO: Test the solution for overriding x-default link when we have pages that don't have default locale (en) version

  // const i18nHeadLinkFiltered = i18nHead.value?.link.filter((item) => {
  //   // keep default and canonical link
  //   if (item.rel === 'canonical' || item.hreflang === 'x-default') {
  //     return true
  //   }
  //   return routeLocales.includes(item.hreflang)
  // }).map((item) => {
  //   // Override x-default link in case the page does not have default locale (en) version
  //   if (item.hreflang === 'x-default' && !routeLocales.includes('en')) {
  //     return { ...item, href: i18nHead.value?.link.find(item => item.rel === 'canonical')?.href }
  //   }
  //   return item
  // })

  // const i18nHeadLinkForTargetSite = i18nHeadLinkFiltered

  // Patch to add tailing slash to home pages alternate links, required by SEO for compatibility with old implementation
  // const i18nHeadLinks = !isHome(route.path)
  //   ? i18nHeadLinkForTargetSite
  //   : i18nHeadLinkForTargetSite.map(item => ({
  //     ...item,
  //     href: item.href.endsWith('/') ? item.href : `${item.href}/` // add tailing slash only if it is not present
  //   }))

  // - Generate  og:locale meta tags only for existing route locales
  // const i18nHeadMetaFiltered = i18nHead?.value?.meta.filter((item) => {
  //   // TODO: remap meta tags to avoid having content
  //   const route = i18nParams.value?.[item.content]
  //   if (!route) {
  //     return false
  //   }

  //   let translatedRouteExists = true
  //   paramsKeys.forEach((key) => {
  //     if (!route[key]) {
  //       translatedRouteExists = false
  //     }
  //   })
  //   return translatedRouteExists
  // }) ?? []

  // -- END of commented block

  // Structured data for search engines: https://codelabs.developers.google.com/codelabs/structured-data/index.html
  const structuredData = context.structuredData
    ? [
        {
          hid: 'head-json-ld',
          type: 'application/ld+json',
          children: JSON.stringify(context.structuredData, null, '')
        }
      ]
    : null

  // useHead is a Nuxt 3 out-of-the-box composable
  // Add language to `html` tag and if needed add the `x-default` link
  useHead({
    ...(structuredData && { script: structuredData }),
    htmlAttrs: {
      lang: i18nHead.value?.htmlAttrs.lang || 'en'
    },
    link: headLinks,
    meta: copy(i18nHead.value?.meta || [])
  })
}
