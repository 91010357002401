import { mapperBlockQuote } from '../block-quote/mapper-block-quote'
import type { MapperOptions } from '#layers/core/types'

/**
 * Block quote mapper
 */
export const mapperBlockQuoteGroup = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    title: data.title || '',
    description: data.quoteDescription || '',
    items: data.quotesCollection?.items.map((quote: any) => mapperBlockQuote(quote, options)) || []
  }
}
