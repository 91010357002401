import { mapperModuleMedia } from '../module-media/mapper-module-media'
import { mapperModuleInternalVideo } from '../module-internal-video/mapper-module-internal-video'
import type { MapperImageOptions, ModuleMedia } from '#layers/core/types'

/**
 * Gets video source
 * prioritizing internal sources
 */
const getVideoSourceData = (data: any) => {
  if (data.videoInternalLink?.length > 0) {
    const internalLink = data.videoInternalLink[0]
    const extension = internalLink.extension?.[0]
    // When the asset is marked as public in Bynder the original asset it returned which has better quality
    return {
      selfHosted: true,
      extension,
      src: internalLink?.original || internalLink.videoPreviewURLs?.find((item) => item.includes(extension))
    }
  }

  if (data.videoExternalLink) {
    return {
      selfHosted: false,
      extension: null,
      src: data.videoExternalLink
    }
  }

  return null
}
export const mapperModuleVideo = (data: any, imageOptions: MapperImageOptions) => {
  if (!data) {
    return null
  }
  if (data.frontifyAsset) {
    return mapperModuleInternalVideo(data, imageOptions)
  }

  // @TODO: change options.sizes to be an object with keys { small, medium, large }

  return {
    id: data.sys?.id || '',
    title: data?.title || '',
    thumbnail: mapperModuleMedia(data.thumbnail, imageOptions) as ModuleMedia,
    source: data?.source || '',
    caption: data?.caption || '',
    sizes: imageOptions?.sizes || {},
    ...getVideoSourceData(data),
    contentType: data.contentType
  }
}
