import { mapperModuleNavigationLink } from '../../modules/mappers'
import type { MapperOptions } from '#layers/core/types'

export const mapperBlockLinksList = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    title: data.title || '',
    links:
      data.linksCollection?.items
        ?.map((item: any) => mapperModuleNavigationLink(item, options.getUrl))
        .filter(Boolean) || []
  }
}
