import { mapperModuleMedia, mapperModuleNavigationLink } from '../../modules/mappers'
import type { ModuleMedia, MapperOptions } from '#layers/core/types'

/**
 * BlockLogoBar
 */
export const mapperBlockLogoBar = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  const { imageOptions, getUrl } = options

  return {
    contentType: data.contentType,
    id: data.sys?.id || data.id,
    items:
      data.logosCollection?.items?.map((item: any) => {
        const image = mapperModuleMedia(item, { ...imageOptions, lazy: true }) as ModuleMedia
        return { ...image.small, isFrontifyAsset: image.original.isFrontifyAsset }
      }) || [],
    footnote: data.footnote || '',
    title: data.title || '',
    description: data.logosDescription || null,
    link: mapperModuleNavigationLink(data.link, getUrl!)
  }
}
