import { gql } from 'graphql-tag'
import fragmentPageKnowledgeHubDetail from '../page-knowledge-hub-detail/fragment-page-knowledge-hub-detail'

/**
 query ($locale: String!, $skip: Int!, $limit: Int!) {
 articles: pageKnowledgeHubDetailCollection (
 order: date_DESC,
 skip: $skip,
 limit: $limit,
 locale: $locale,
 where: {
 { products: { sys: { id_in: ["A_PRODUCT_ID", "ANOTHER_PRODUCT_ID"] } } },
 { industries: { sys: { id_in: ["AN_INDUSTRY_ID", "ANOTHER_INDUSTRY_ID"] } } },
 { topics: { sys: { id: "A_TOPIC_ID" } } },
 { articleType: { sys: { id: "AN_ARTICLE_ID" } } }
 ]
 }
 ) {
 items {
 ...PageKnowledgeHubDetailFragment
 }
 }
 }
 */

/**
 * Receives a fieldName and an array of ids and returns the GQL fragment for the conditions for this field as an OR clause
 * @param {String} fieldName (Possible values: 'products', 'industries', 'topics', 'articleType')
 * @param {String[]} entriesIds expects an array of the `sys.id` values of the entries that should be referenced by the field
 */
function buildClause(fieldName, entriesIds) {
  if (entriesIds && entriesIds.length > 0) {
    if (entriesIds.length > 1) {
      return `${fieldName}: { sys: { id_in: ${JSON.stringify(entriesIds)} } }`
    } else {
      const [id] = entriesIds
      return `${fieldName}: { sys: { id: "${id}" } } `
    }
  } else {
    return ''
  }
}

/**
 * Receives the filter values (ids of the expected referenced entries in each field) and builds the QGL query
 * @param {String[]} articleTypeIds ids of the entries that should be linked (at least one) to the field articleType
 * @param {String[]} productIds     ids of the entries that should be linked (at least one) to the field products
 * @param {String[]} industryIds    ids of the entries that should be linked (at least one) to the field industries
 * @param {String[]} topicIds       ids of the entries that should be linked (at least one) to the field topics
 */

// new approach:
// where: {slug_exists: true, AND: [
//   { OR: [ {campaign_exists: false}, {campaign: {visibleInKnowledgeHub: true}} ] },
//   { OR: [ {retailReport_exists: false}, {retailReport: {visibleInKnowledgeHub: true}} ] }
// ]}

// AND: [ { OR: [ {campaign_exists: false}, {campaign: {visibleInKnowledgeHub_not: false}} ] }, { OR: [ {retailReport_exists: false}, {retailReport: {visibleInKnowledgeHub: true}} ] } ] }

// old approach: -> issue showing `visibleInKnowledgeHub=false` campaing in KH latests posts and articles
// 'OR:[ { campaign_exists: false }, { campaign:{ visibleInKnowledgeHub_not: false } }, { retailReport_exists: false }, { retailReport: { visibleInKnowledgeHub_not: false } } ]',
function buildQuery(articleTypeIds, productIds, industryIds, topicIds) {
  const conditions = [
    'slug_exists: true',
    'visibleInKnowledgeHub_not: false',
    buildClause('articleType', articleTypeIds),
    buildClause('products', productIds),
    buildClause('industries', industryIds),
    buildClause('topics', topicIds)
  ]
    .filter(Boolean)
    .join(', ')
  const conditionsFragment = `where: { ${conditions} }`
  return gql`
        ${fragmentPageKnowledgeHubDetail}
        query ($locale: String!, $skip: Int!, $preview: Boolean!, $limit: Int!) {
            articles: pageKnowledgeHubDetailCollection (
                order: date_DESC,
                skip: $skip,
                limit: $limit,
                locale: $locale,
                preview: $preview,
                ${conditionsFragment}
            ) {
                items {
                    ...PageKnowledgeHubDetailFragment
                },
                total
            }
        }
    `
}

export default buildQuery
