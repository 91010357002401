import { useTypeMappers } from './type-mappers'
import type { DefinitionField, MapperImageOptions, MapperOptions } from '#layers/core/types'

/**
 * Entry dynamic mapper
 * normalizes data to be used in pages
 */

const imageOptionsForField = (field: DefinitionField, args: any) => {
  let imageOptions: MapperImageOptions
  if (field.hooks?.imageOptions) {
    imageOptions = field.hooks.imageOptions(args)
    return imageOptions || field.imageOptions
  }
  return field.imageOptions
}

export default (data: any, options: MapperOptions, layerMappers: any) => {
  if (!data) {
    return {}
  }

  const { mapperForType } = useTypeMappers(layerMappers)

  const imageOptions = imageOptionsForField(data, { contentType: data?.contentType })
  const mapper = mapperForType(data.contentType)
  const mappedData = mapper(data, { ...options, imageOptions })
  if (options?.withContentType) {
    mappedData.type = data.contentType
    mappedData.contentType = data.contentType
  }
  return mappedData
}
