import { buildFiltersQuery } from '../../../utils/knowledge-hub-utils'
import { mapperKnowledgeHubArticlesSummaries } from '../../knowledge-hub/summary/mapper-knowledge-hub-articles-summaries'
import type { MapperOptions } from '#layers/core/types'

/**
 * Block Related Content mapper
 */
export default (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    title: data.title || '',
    filtersQuery: buildFiltersQuery(data.filtersCollection?.items || []),
    items: mapperKnowledgeHubArticlesSummaries(data.relatedPagesCollection?.items, options) || []
  }
}
