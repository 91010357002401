import type { MapperImageSize, MapperImageSizes } from '#layers/core/types'
import { hexToRgba } from './hexToRgba'
import { DEFAULT_SIZES } from './mapper-image'

/**
 * Determine if the image should use an automatic height
 */
const isHeightAuto = (height: number | string): boolean => !height || height === 'auto' || Number.isNaN(height)

/**
 * getTransformedUrl, adds resizing, cropping and zoom transformations to the url
 * @see https://developer.frontify.com/d/XFPCrGNrXQQM/asset-processing-api#/operations/resizing
 * @see https://developer.frontify.com/d/XFPCrGNrXQQM/asset-processing-api#/operations/cropping-focal-point
 *
 * @example
 * getTransformedUrl('url', { width: 300, height: 100 }, [0.3, 0.7], 150)
 */
const getTransformedUrl = (
  url: string,
  { width, height }: MapperImageSize,
  focalPoint: Array<number>,
  zoom?: number
) => {
  if (!url) {
    return ''
  }

  const [focalPointX, focalPointY] = focalPoint || [0.5, 0.5]

  if (isHeightAuto(height)) {
    // resized using only width to let the height be set automatically based on the image
    return `${url}?width=${width}&format=webp`
  }

  let transformedUrl = `${url}?width=${width}&height=${height}&format=webp&crop=fp&fp=${focalPointX},${focalPointY}`
  // Apply zoom if it exceeds default value and doesn't exceed maximum value
  if (zoom && zoom > 100 && zoom <= 300) {
    const fpZoomParam = zoom / 100
    transformedUrl += `&fp_zoom=${fpZoomParam}`
  }

  // resize the image with width and height
  return transformedUrl
}

const getTransformedUrlSVG = (url: string, { width, height }: MapperImageSize, recolor?: string) => {
  if (!url) {
    return ''
  }
  let recolorParam = ''
  if (recolor) {
    const rgbaColor = hexToRgba(recolor)
    recolorParam = `color=${rgbaColor}`
  }
  const hasParams = recolorParam !== ''
  const params = `${recolorParam}`

  return `${url}${hasParams ? '?' : ''}${params}`
}

/**
 * Get the multiplier for large images to support retina screens
 *
 */
const getImageLargeMultiplier = (largeSizeOption: MapperImageSize, width: number, height: number) => {
  const autoHeight = isHeightAuto(largeSizeOption.height)
  const heightAsNumber = autoHeight ? 0 : (largeSizeOption.height as number)
  switch (true) {
    case width >= largeSizeOption.width * 2 && (autoHeight || height >= heightAsNumber * 2):
      // check if the image is big enough to support size*2 on large screens
      return 2
    case width >= largeSizeOption.width * 1.5 && (autoHeight || height >= heightAsNumber * 1.5):
      // check if the image is big enough to support size*1.5 on large screens
      return 1.5
    default:
      return 1
  }
}

/**
 * Mapper for Frontify images
 */
export default (image: any, sizes?: MapperImageSizes, recolor?: string, zoom?: number) => {
  const small = sizes?.small || DEFAULT_SIZES.small
  const medium = sizes?.medium || DEFAULT_SIZES.medium
  const large = sizes?.large || DEFAULT_SIZES.large
  const imageName = image?.name || ''
  const imageUrl = image?.src

  const hasSizes = !!(Object.keys(small).length && Object.keys(medium).length && Object.keys(large).length)

  // When image extension is svg or no sizes are set do not apply any transformations
  if (!hasSizes) {
    const url = image?.src
    return {
      small: { src: url, name: imageName, aspectRatio: null },
      medium: { src: url, name: imageName, aspectRatio: null },
      large: { src: url, name: imageName, aspectRatio: null }
    }
  }

  // Multiply support retina screens
  const imageLargeMultiplier = getImageLargeMultiplier(large, image?.size?.width, image?.size?.height)
  const imageSmall = {
    width: small.width * 2,
    height: typeof small.height === 'string' ? small.height : small.height * 2,
    aspectRatio: small.aspectRatio
  }
  const imageMedium = {
    width: medium.width * 2,
    height: typeof medium.height === 'string' ? medium.height : medium.height * 2,
    aspectRatio: medium.aspectRatio
  }
  const imageLarge = {
    width: Math.floor(large.width * imageLargeMultiplier),
    height: typeof large.height === 'string' ? large.height : Math.floor(large.height * imageLargeMultiplier),
    aspectRatio: large.aspectRatio
  }

  const isSVG = image.extension === 'svg'
  const shouldResize = image.frontifyType === 'Image' && image.extension !== 'svg'

  // For logos that are recolored to WHITE we want to replace the background in case it's the default light-grey for the adyen-black so it has contrast
  const lowContrastBgColor = '#f7f7f8'
  const alternativeBgColor = '#00112c'
  let backgroundColor = image.color
  if (
    recolor &&
    (recolor.toLowerCase() === '#ffffff' || recolor.toLowerCase() === '#fff') &&
    image.color?.toLowerCase() === lowContrastBgColor
  ) {
    backgroundColor = alternativeBgColor
  }

  return {
    small: {
      src: isSVG
        ? getTransformedUrlSVG(imageUrl, imageSmall, recolor)
        : getTransformedUrl(imageUrl, imageSmall, image.focalPoint, zoom),
      name: imageName,
      aspectRatio: shouldResize ? imageSmall.aspectRatio : '',
      color: backgroundColor,
      dimensions: isSVG ? null : { width: small.width, height: small.height }
    },
    medium: {
      src: isSVG
        ? getTransformedUrlSVG(imageUrl, imageMedium, recolor)
        : getTransformedUrl(imageUrl, imageMedium, image.focalPoint, zoom),
      name: imageName,
      aspectRatio: shouldResize ? imageMedium.aspectRatio : '',
      color: backgroundColor,
      dimensions: isSVG ? null : { width: medium.width, height: medium.height }
    },
    large: {
      src: isSVG
        ? getTransformedUrlSVG(imageUrl, imageLarge, recolor)
        : getTransformedUrl(imageUrl, imageLarge, image.focalPoint, zoom),
      name: imageName,
      aspectRatio: shouldResize ? imageLarge.aspectRatio : '',
      color: backgroundColor,
      dimensions: isSVG ? null : { width: large.width, height: large.height }
    }
  }
}
