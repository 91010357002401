import { mapperModuleNavigationLink } from '../../modules/mappers'
import type { MapperOptions } from '#layers/core/types'

/**
 * Mapper page seo fields
 * normalizes data to be used in pages
 *
 */
export const mapperPageSeo = (data: any) => {
  if (!data) {
    return null
  }

  const robotsFollow = data.noFollow ? 'nofollow' : 'follow'
  const robotsIndex = data.noIndex ? 'noindex' : 'index'
  const ogImage =
    data?.ogImage && data.ogImage?.frontifyAsset ? data.ogImage.frontifyAsset?.[0] : data.ogImage?.src?.[0]?.thumbnails
  const isFrontify = !!data.ogImage?.frontifyAsset

  // Change the Open Graph image format to PNG
  // if the asset is from Frontify and it's a SVG
  let ogImageSrc: string | undefined = ogImage?.src || ogImage?.original || ogImage?.webimage
  if (isFrontify && ogImage?.ext?.toLowerCase() === 'svg') {
    ogImageSrc += '?format=png'
  }

  return {
    title: data.title || '',
    description: data.description || '',
    keywords: data.keywords?.join(', ') || '',
    robots: `${robotsIndex}, ${robotsFollow}`,
    ...(ogImageSrc && { ogImage: ogImageSrc })
  }
}

export const mapperPageBreadcrumbs = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }
  // module navigation link
  if (data.externalLink || data.internalLink?.sys?.id) {
    return mapperModuleNavigationLink(data, options.getUrl)
  }

  // parent page link
  if (data.sys?.id) {
    return { title: data.title || '', url: options.getInternalUrl(data.sys.id) }
  }

  return null
}
