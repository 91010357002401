import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { mapperModuleMedia, mapperModuleNavigationLink, mapperModuleVideo } from '../../modules/mappers'
import mapperIcon from '../../utils/mappers/mapper-icon'
import type { MapperOptions } from '#layers/core/types'

export const mapperBlockContent = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  const links = data.linksCollection?.items
    ?.map((link: any) => mapperModuleNavigationLink(link, options.getUrl))
    .filter(Boolean)

  return {
    contentType: data.contentType || '',
    id: data.sys?.id || '',
    title: data.title || '',
    description: options.transformShortDescription
      ? documentToPlainTextString(data.contentDescription?.json || {})
      : data.contentDescription || '',
    icon: mapperIcon(data.icon),
    eyebrow: data.eyebrow || '',
    image: mapperModuleMedia(data.image, options.imageOptions),
    video: mapperModuleVideo(data.video, options.imageOptions),
    links: links || [],
    link: links?.[0]
  }
}
